import type { VariantProps} from 'class-variance-authority';

import { cn } from '#app/utils/misc';
import { cva } from 'class-variance-authority';
import React from 'react';

import { Icon } from '../icon';


const buttonVariants = cva(
  'justify-center items-center grid grid-flow-col gap-1.5 rounded-md text-body-2-bold cursor-pointer',
  {
    defaultVariants: {
      rounded: 'none',
      size   : 'small',
      variant: 'gray',
    },
    variants: {
      rounded: {
        full: 'rounded-full',
        lg  : 'rounded-lg',
        md  : 'rounded-md',
        none: 'rounded-none',
        xl  : 'rounded-xl',
        xslg: 'rounded-[10px]',
      },
      size: {
        large : 'px-5 text-label-1-bold xs:font-sfProBold sm:font-pretendardBold h-[3.25rem]',
        medium: 'px-4 text-label-2-bold xs:font-sfProBold sm:font-pretendardBold h-[2.625rem]',
        small : 'px-3.5 text-caption-2-bold xs:font-sfProBold sm:font-pretendardBold h-[2.125rem]',
      },
      variant: {
        black  : 'text-white bg-gray-100',
        error  : 'text-white bg-error-red',
        gray   : 'text-gray-90 bg-gray-10',
        primary: 'text-white bg-point-blue-70'
      },
    },
  },
);

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>,
 VariantProps<typeof buttonVariants> {
   'data-umami-event'?: string;
  loading?: 'false' | 'true';
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, rounded, size, variant, ...props }, ref) => {
    return (
      <div className={
        cn(
          'grid relative items-center',
          props.loading === 'true' ? 'pointer-events-none': '',
        )
      }>
        <button
          className={cn(
            buttonVariants({ className, rounded, size, variant }),
            'disabled:pointer-events-none disabled:bg-gray-30  disabled:text-gray-50',
          )}
          {...props}
          children={
            props.loading === 'true'
              ? <>
                <Icon className='animate-spin' name='spinner' size={size ?? 'small'} />
              </>
              : props.children
          }
          disabled={props.disabled}
          ref={ref}
        />
        <button 
          className={
            cn(
              `rounded-${rounded ?? 'none'} cursor-pointer`,
              'absolute hover:bg-alpha-1.5',
              // `active:ring-${variant === 'primary'? 'point-blue-80' : 'gray-40'} `,
              'disabled:pointer-events-none disbled:bg-gray-30 disabled:text-gray-50',
            )} 
          data-umami-event={props['data-umami-event']}
          disabled={props.disabled}
          name={props.name}
          onClick={props.onClick}
          style={{height: '100%', width: '100%'}}
          type={props.type}
          value={props.value}
        />
      </div>
    );
  });
  
Button.displayName = 'Button';
