import { type Config } from 'tailwindcss';

export const extendedTheme = {
  fontFamily: {
    pretendardMedium: ['Pretendard-Medium',],
    pretendardBold: ['Pretendard-Bold',],
    pretendardLight: ['Pretendard-Light',],
    sfProBold: ['SFPro-Bold'],
    sfProMedium: ['SFPro-Medium'],
    sfProLight: ['SFPro-Light']
  },
  screens: {
    xs: '280px',
    sm: '668px',
    md: '860px',
    lg: '1052px',
    xl: '1244px',
    '2xl': '1436px',
  },
  animation: {
    'book-item-in'    : 'book-item-in 0.3s normal forwards ease',
    'book-item-out'   : 'book-item-out 0.3s normal forwards ease',
    'sidebar-item-in' : 'sidebar-item-in 0.2s normal forwards ease',
    'sidebar-item-out': 'sidebar-item-out 0.2s normal forwards ease',
    'rotate-180': 'rotate-180 0.3s normal forwards ease',
    'rotate-0': 'rotate-0 0.3s normal forwards ease',
  },
  borderColor: {
    DEFAULT: 'hsl(var(--border))',
  },
  borderRadius: {
    '2.5xl': 'calc(var(--radius) + 4px)',
    lg: 'var(--radius)',
    md: 'calc(var(--radius) - 2px)',
    sm: 'calc(var(--radius) - 4px)',
  },
  boxShadow: {
    'book-shadow': '0px 4px 20px rgba(0, 0, 0, 0.15)',
  },
  backgroundImage: () => ({
    'shelf-gradient': 'linear-gradient(0deg, #FFFFFF, #FFFFFF),linear-gradient(180deg, #FFFFFF 0%, #FCFCFC 61.72%, #FFFFFF 134.62%)',
    'signup': "url('/other/svg-icons/signup.svg')",
    'start-bookcovery-gradient': 'linear-gradient(180deg, rgba(255, 255, 255, 0) 35.48%, #FFFFFF 100%)',
  }),
  colors: {
    error: {
      red: 'rgb(var(--error-red))',
      darkRed: 'rgb(var(--error-dark-red))',
    },
    'alpha-0'    : 'rgba(0, 0, 0, 0.0)',
    'alpha-1.2'    : 'rgba(0, 0, 0, 0.12)',
    'alpha-1.5'    : 'rgba(0, 0, 0, 0.15)',
    'alpha-2': 'rgba(0, 0, 0, 0.4)',
    'alpha-3': 'rgba(0, 0, 0, 0.6)',
    'alpha-4': 'rgba(0, 0, 0, 0.9)',
        
    background : 'hsl(var(--background))',
    destructive: {
      DEFAULT   : 'hsl(var(--destructive))',
      foreground: 'hsl(var(--destructive-foreground))',
    },
    foreground: {
      DEFAULT    : 'hsl(var(--foreground))',
      destructive: 'hsl(var(--foreground-destructive))',
    },
    verified: 'rgb(var(--verified))',
    gray: {
      5  : 'rgb(var(--gray-5))',
      10 : 'rgb(var(--gray-10))',
      20 : 'rgb(var(--gray-20))',
      30 : 'rgb(var(--gray-30))',
      40 : 'rgb(var(--gray-40))',
      50 : 'rgb(var(--gray-50))',
      60 : 'rgb(var(--gray-60))',
      70 : 'rgb(var(--gray-70))',
      80 : 'rgb(var(--gray-80))',
      90 : 'rgb(var(--gray-90))',
      100: 'rgb(var(--gray-100))',
    },
    point: {
      blue: {
        5 : 'rgb(var(--point-blue-5))',
        10: 'rgb(var(--point-blue-10))',
        20: 'rgb(var(--point-blue-20))',
        30: 'rgb(var(--point-blue-30))',
        40: 'rgb(var(--point-blue-40))',
        50: 'rgb(var(--point-blue-50))',
        60: 'rgb(var(--point-blue-60))',
        70: 'rgb(var(--point-blue-70))',
        80: 'rgb(var(--point-blue-80))',
      }
    },
    pink: {
      10: 'rgb(var(--pink-10))',
      50: 'rgb(var(--pink-50))',
      70: 'rgb(var(--pink-70))',
    },
  },
  fontSize: {
    // 1rem = 16px

    /** 16px, 700, 135%*/
    'body-1-bold': ['1rem', { fontWeight: '600', letterSpacing: '-0.01em', lineHeight: '135%' }],
    /** 16px, 400, 135%*/
    'body-1-regular': ['1rem', { fontWeight: '400', letterSpacing: '-0.01em', lineHeight: '135%' }],


    /** 14px, 700, 162% */
    'body-2-bold': ['0.875rem', { fontWeight: '600', letterSpacing: '-0.01em', lineHeight: '1.18125rem' }],
    /** 14px, 500, 162% */
    'body-2-medium': ['0.875rem', { fontWeight: '500', letterSpacing: '-0.01em', lineHeight: '1.18125rem' }],
    /** 14px, 400, 162% */
    'body-2-regular': ['0.875rem', { fontWeight: '400', letterSpacing: '-0.01em', lineHeight: '1.18125rem' }],

    /** 13px 700 17.55px */
    'caption-1-bold'  : ['0.8125rem', { fontWeight: '600', lineHeight: '1.096rem' }],
    /** 13px 500 17.55px */
    'caption-1-medium': ['0.8125rem', { fontWeight: '500', lineHeight: '1.096rem' }],
    /** 13px 400 17.55px */
    'caption-1-regular': ['0.8125rem', { fontWeight: '400', lineHeight: '1.096rem' }],
    
    /** 13px 700 140% */
    'caption-2-bold'   : ['0.813rem', { fontWeight: '600', lineHeight: '140%'}],
    /** 13px 500 140% */
    'caption-2-medium' : ['0.813rem', { fontWeight: '500', lineHeight: '140%'}],
    /** 13px 400 140% */
    'caption-2-regular': ['0.813rem', { fontWeight: '400', lineHeight: '140%'}],

    /** 11px 700 140% */
    'caption-3-bold': ['0.6875rem', { fontWeight: '600', lineHeight: '140%'}],
    /** 11px 500 140% */
    'caption-3-medium': ['0.6875rem', { fontWeight: '500', lineHeight: '140%'}],
    /** 11px 400 140% */
    'caption-3-regular': ['0.6875rem', { fontWeight: '400', lineHeight: '140%'}],

    'heading-1-bold'   : ['3.75rem', { fontWeight: '600', letterSpacing: '-0.01em', lineHeight: '135%' }],
    'heading-2-bold'   : ['3.375rem', { fontWeight: '600', letterSpacing: '-0.01em', lineHeight: '135%' }],
    'heading-3-bold'   : ['3rem', { fontWeight: '600', letterSpacing: '-0.01em', lineHeight: '3rem' }],

    /** 42px 700  */
    'heading-4-bold': ['2.625rem', { fontWeight: '600', letterSpacing: '-0.01em', lineHeight: '2.25rem' }],

    /** 16px 700 21.6px */
    'label-1-bold'   : ['1rem', { fontWeight: '600', lineHeight: '1.35rem' }],
    /** 16px 700 21.6px */
    'label-1-medium' : ['1rem', { fontWeight: '500', lineHeight: '1.35rem' }],
    /** 16px 700 21.6px */
    'label-1-regular': ['1rem', { fontWeight: '400', lineHeight: '1.35rem' }],

    /** 14px 700 18.9px */
    'label-2-bold'   : ['0.875rem', { fontWeight: '600', lineHeight: '1.181rem' }],
    /** 14px 500 18.9px */
    'label-2-medium' : ['0.875rem', { fontWeight: '500', lineHeight: '1.181rem' }],
    /** 14px 400 18.9px */
    'label-2-regular': ['0.875rem', { fontWeight: '400', lineHeight: '1.181rem' }],
    
    
    /** 20px 700 27px */
    'subtitle-1-bold-desktop': ['1.125rem', { fontWeight: '600', lineHeight: '1.6875rem' }],
    /** 20px 500 27px */
    'subtitle-1-medium-desktop': ['1.125rem', { fontWeight: '500', lineHeight: '1.6875rem' }],
    /** 20px 400 27px */
    'subtitle-1-regular-desktop': ['1.125rem', { fontWeight: '400', lineHeight: '1.6875rem' }],

    'subtitle-1-bold': ['1.125rem', { fontWeight: '600', lineHeight: '1.51rem' }],


    'tiny-bold'      : ['0.625rem', { fontWeight: '600',  lineHeight: '0.843rem' }],
    'tiny-medium'    : ['0.625rem', { fontWeight: '500',  lineHeight: '0.843rem' }],
    'tiny-regular'           : ['0.625rem', { fontWeight: '400',  lineHeight: '0.843rem' }],

    /** 32px, 700, 150% */
    'title-1-bold-desktop'   : ['2rem', { fontWeight: '600', letterSpacing: '-0.01em', lineHeight: '150%' }],
    /** 24px, 700, 150% */
    'title-1-bold-mobile'    : ['1.5rem', { fontWeight: '600', letterSpacing: '-0.01em', lineHeight: '150%' }],
    /** 32px, 400, 150% */
    'title-1-regular-desktop': ['2rem', { fontWeight: '400', letterSpacing: '-0.01em', lineHeight: '150%' }],
    /** 24px, 400, 150% */
    'title-1-regular-mobile' : ['1.5rem', { fontWeight: '400', letterSpacing: '-0.01em', lineHeight: '150%' }],
    /** 26px, 700, 130% */
    'title-2-bold-desktop'   : ['1.5rem', { fontWeight: '600', letterSpacing: '-0.01em', lineHeight: '130%' }],
    /** 20px, 700, 130% */
    'title-2-bold-mobile'    : ['1.25rem', { fontWeight: '600', letterSpacing: '-0.01em', lineHeight: '130%' }],
    /** 24px, 400 130% */
    'title-2-regular-desktop': ['1.5rem', { fontWeight: '400', letterSpacing: '-0.01em', lineHeight: '130%' }],
    
    /** 20px, 400 130% */
    'title-2-regular-mobile': ['1.25rem', { fontWeight: '400', letterSpacing: '-0.01em', lineHeight: '130%' }],

    /** 16px 700 22px */
    'body-1-paragraph-bold': ['1rem', { fontWeight: '600', letterSpacing: '-0.01em', lineHeight: '1.62rem' }],
    /** 16px 500 22px */
    'body-1-paragraph-medium': ['1rem', { fontWeight: '500', letterSpacing: '-0.01em', lineHeight: '1.62rem' }],
    /** 16px 400 22px */
    'body-1-paragraph-regular': ['1rem', { fontWeight: '400', letterSpacing: '-0.01em', lineHeight: '1.62rem' }],

    /** 14px 700 22px */
    'body-2-paragraph-bold': ['0.875rem', { fontWeight: '600', letterSpacing: '-0.01em', lineHeight: '1.4175rem' }],
    /** 14px 500 22px */
    'body-2-paragraph-medium': ['0.875rem', { fontWeight: '500', letterSpacing: '-0.01em', lineHeight: '1.4175rem' }],
    /** 14px 400 22px */
    'body-2-paragraph-regular': ['0.875rem', { fontWeight: '400', letterSpacing: '-0.01em', lineHeight: '1.4175rem' }],
  },
  gradientColorStops: {
    'book-hover-1': 'rgba(0, 0, 0, 0.40)',
    'book-hover-2': 'rgba(0, 0, 0, 0.60)',
  },
  gridAutoColumns: {
    'sidebar': '14.75rem auto',
  },
  gridAutoRows: {
    'layout': '5rem auto',
    'max'   : 'max-content',
  },
  gridTemplateColumns: {
    'autofit-1fr': 'repeat(auto-fit, minmax(100px, auto))',
  },
  keyframes: {
    'book-item-in': {
      '0%'  : { transform: 'translateY(0rem)' },
      '100%': { transform: 'translateY(-1.5rem)' },
    },
    'book-item-out': {
      '0%'  : { transform: 'translateY(-1.5rem)' },
      '100%': { transform: 'translateY(0rem)' },
    },
    'sidebar-item-in': {
      '0%'  : { transform: 'translate(0rem)' },
      '100%': { transform: 'translate(0.75rem)' },
    },
    'sidebar-item-out': {
      '0%'  : { transform: 'translate(0.75rem)' },
      '100%': { transform: 'translate(0rem)' },
    },
    'rotate-180': {
      '0%'  : { transform: 'rotate(0deg)' },
      '100%': { transform: 'rotate(180deg)' },
    },
    'rotate-0': {
      '0%'  : { transform: 'rotate(180deg)' },
      '100%': { transform: 'rotate(0deg)' },
    },
  },
  width: {
    1344: '84rem'
  },
} satisfies Config['theme'];
